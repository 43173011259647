import ROUTES from "@/routing/routes";

export const headerNavLinks = [
  {
    title: "Про нас",
    path: ROUTES.home,
  },
  {
    title: "Умови використання",
    path: ROUTES.home,
  },
  {
    title: "Політика конфіденційності",
    path: ROUTES.home,
  },
];
