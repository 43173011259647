import { Flex } from "antd";
import React, { useState } from "react";

import { PostList } from "../../components/post/posts-list";
import { FullscreenAvatars } from "../../components/profile/fullscreen-avatars";
import { ProfileBio } from "../../components/profile/profile-bio";
import ProfileHeader from "../../components/profile/profile-header";
import { ProfileHeaderActions } from "../../components/profile/profile-header/components/profile-header-actions";
import { ProfileModals } from "../../components/profile/profile-modals";
import { ProfileNoPosts } from "../../components/profile/profile-no-posts";
import { Spinner } from "../../components/ui/spinner";
import {
  useGetSelfProfileQuery,
  useUpdateProfileMutation,
} from "../../features/api/user-api";
import useProfilePosts from "../../hooks/use-profile-posts";
import {
  selfProfileActions,
  selfProfileActionsValues as selfProfileValues,
} from "../../utils/constants/options";
import styles from "./profile.module.scss";

export const Profile = () => {
  const [activeModal, setActiveModal] = useState(null);
  const [isBioEditing, setIsBioEditing] = useState(false);
  const [fullScreenOpenedImage, setFullScreenOpenedImage] = useState(null);
  const [updateProfile] = useUpdateProfileMutation();
  const { data: profileData, isFetching: isProfileLoading } =
    useGetSelfProfileQuery();
  const {
    posts,
    error,
    isLoading,
    onPostAction,
    getPosts,
    hasMorePosts,
    isFetchingNextPage,
  } = useProfilePosts({
    skip: !profileData?.id,
    profile_id: profileData?.id,
  });

  const onProfileEditSelect = ({ key }) => {
    if (key === selfProfileValues.edit_bio) {
      setIsBioEditing(true);
    }
    if (key === selfProfileValues.edit_avatar) {
      setActiveModal(selfProfileValues.edit_avatar);
    }
    if (key === selfProfileValues.edit_cover) {
      setActiveModal(selfProfileValues.edit_cover);
    }
  };
  const onProfileEditCancel = () => {
    setIsBioEditing(false);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const onAvatarClick = async () => {
    if (profileData?.profile_image?.large?.url) {
      setFullScreenOpenedImage({
        index: 0,
        image: profileData?.profile_image?.large?.url,
      });
    }
  };

  const onProfileEditSave = (newProfileBio) => {
    updateProfile({
      bio: newProfileBio,
    });
    setIsBioEditing(false);
  };

  return (
    <div className={styles.twoColumns}>
      {fullScreenOpenedImage && (
        <FullscreenAvatars
          onAttachmentEvent={setFullScreenOpenedImage}
          startIndex={fullScreenOpenedImage.index}
          profileId={profileData?.id}
        />
      )}
      <ProfileModals activeModal={activeModal} onClose={closeModal} />
      <Flex vertical className={styles.column}>
        <ProfileHeader
          onAvatarClick={onAvatarClick}
          profile={profileData}
          isLoading={isProfileLoading}
          topRightComponent={
            <>
              <ProfileHeaderActions
                onActionCLick={onProfileEditSelect}
                items={selfProfileActions}
              />
            </>
          }
          bioComponent={
            <ProfileBio
              isEditing={isBioEditing}
              value={profileData?.bio}
              onCancel={onProfileEditCancel}
              onSave={onProfileEditSave}
            />
          }
        />
        <div>
          <PostList
            posts={posts}
            onAction={onPostAction}
            getPosts={getPosts}
            hasMorePosts={hasMorePosts}
          />
          {posts?.length === 0 && !isLoading && <ProfileNoPosts />}
          {isFetchingNextPage && <Spinner />}
          {error?.length && <div>{error}</div>}
        </div>
      </Flex>
    </div>
  );
};
