import bg from "@/assets/images/auth-img.webp";
import { Outlet } from "react-router-dom";

import AuthHeader from "../../auth/header/header";
import styles from "./layout-auth.module.scss";

const LayoutAuth = () => {
  return (
    <div className={styles.wrap}>
      <AuthHeader />
      <div className={styles.formWrap}>
        <div className={styles.formContainer}>
          <div
            className={styles.formImg}
            style={{ backgroundImage: "url(" + bg + ")" }}
          />
          <div className={styles.form}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutAuth;
