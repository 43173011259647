import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import image from "../../assets/images/error.svg";
import styles from "./error.module.scss";

const ErrorPage = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };
  return (
    <div className={styles.errorWrap}>
      <div className={styles.errorBox}>
        <h1 className={styles.errorTitle}>Упс, щось пішло не так</h1>
        <img className={styles.errorImg} src={image} alt="Error" />
        <Button type="primary" size="large" onClick={routeChange}>
          На головну сторінку
        </Button>
      </div>
    </div>
  );
};

export { ErrorPage };
