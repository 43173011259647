import * as vars from "@/utils/constants/variables";
import { checkPasswordRequirements } from "@/utils/helpers/string-transform";
import { Button, Divider, Flex, Input, Space } from "antd";
import { Formik } from "formik";
import React from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import * as Yup from "yup";

import FormError from "../form-error";
import styles from "./create-account.module.scss";

const PasswordCheck = ({ password }) => {
  const { hasDigit, hasLowerCase, hasUpperCase, hasSymbol, hasMinimumLength } =
    checkPasswordRequirements(password);
  return (
    <div className={styles.formLabel}>
      Ваш пароль має містити від
      <span
        style={{
          color: !hasMinimumLength && vars.colors.red,
        }}
      >
        {" "}
        8 символів
      </span>{" "}
      та{" "}
      <span style={{ color: !hasLowerCase && vars.colors.red }}>
        включати великі
      </span>{" "}
      та{" "}
      <span style={{ color: !hasUpperCase && vars.colors.red }}>
        малі літери
      </span>
      ,<span style={{ color: !hasDigit && vars.colors.red }}>цифри</span>,{" "}
      <span style={{ color: !hasSymbol && vars.colors.red }}>
        спеціальні символи (!@#$%^&*)
      </span>
    </div>
  );
};

const newUserSchema = Yup.object().shape({
  first_name: Yup.string().required("Обов'язкові дані"),
  display_name: Yup.string().required("Обов'язкові дані"),
  email: Yup.string().email("Невірний email").required("Обов'язкові дані"),
  password: Yup.string()
    .min(8, "Мінімум 8 символів")
    .matches(/[a-z]+/, "Мінімум одна маленька літера")
    .matches(/[A-Z]+/, "Мінімум одна велика літера")
    .matches(/[^a-zA-Z0-9]/, "Мінімум один спеціальний символ")
    .matches(/\d+/, "Мінімум одна цифра")
    .required("Будь ласка, придумайте пароль"),
  password_confirmation: Yup.string()
    .required("Будь ласка, повторіть пароль")
    .oneOf([Yup.ref("password")], "Паролі не збігаються"),
});

const formatErrors = (errors) => {
  return errors[0];
};

const CreateAccountForm = ({ onSubmit }) => {
  return (
    <>
      <Formik
        initialValues={{
          first_name: "",
          display_name: "",
          email: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={newUserSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);
          onSubmit(values)
            .catch((error) => {
              if (error?.errors.length > 0) {
                error?.errors.forEach((value) => {
                  Object.keys(value).forEach((key) => {
                    setFieldError(key, formatErrors(value[key]));
                  });
                });
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <Flex vertical gap={16}>
            <Space direction="vertical" size={null}>
              <Input
                size={"large"}
                type="email"
                name="email"
                autoComplete="email"
                placeholder="Електронна пошта*"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email ? (
                <FormError>{errors.email}</FormError>
              ) : (
                <div className={styles.formLabel}>
                  Ваша електронна адреса, якою ви користуєтесь
                </div>
              )}
            </Space>
            <Space direction="vertical" size={null}>
              <Input.Password
                size={"large"}
                name="password"
                autoComplete="none"
                placeholder="Пароль"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                iconRender={(visible) =>
                  visible ? <FaRegEye /> : <FaRegEyeSlash />
                }
              />
              {errors.password && touched.password && (
                <FormError>{errors.password}</FormError>
              )}
              {!touched.password && (
                <PasswordCheck password={values.password} />
              )}
              {touched.password && values.password && !errors.password && (
                <div className={styles.formLabel}>
                  Ваш пароль відповідає правилам безпеки
                </div>
              )}
            </Space>
            <Space direction="vertical" size={null}>
              <Input.Password
                autoComplete="new-password"
                size={"large"}
                type="password"
                name="password_confirmation"
                placeholder="Повторіть пароль"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passwordconfirm}
                iconRender={(visible) =>
                  visible ? <FaRegEye /> : <FaRegEyeSlash />
                }
              />
              {errors.password_confirmation &&
                touched.password_confirmation && (
                  <FormError>{errors.password_confirmation}</FormError>
                )}
            </Space>
            <Divider style={{ margin: "8px 0" }} />
            <Space direction="vertical" size={null}>
              <Input
                size={"large"}
                type="text"
                name="first_name"
                placeholder="Ім’я та прізвище"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
              />
              {errors.first_name && touched.first_name ? (
                <FormError>{errors.first_name}</FormError>
              ) : (
                <div className={styles.formLabel}>
                  Ваше повне ім’я та прізвище
                </div>
              )}
            </Space>
            <Space direction="vertical" size={null}>
              <Input
                size={"large"}
                type="text"
                name="display_name"
                placeholder="Нікнейм*"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.display_name}
              />
              {errors.display_name && touched.display_name ? (
                <FormError>{errors.display_name}</FormError>
              ) : (
                <div className={styles.formLabel}>
                  Те, як ви хочете щоб вас бачили інші користувачі
                </div>
              )}
            </Space>
            <Button
              className={styles.formButton}
              size={"large"}
              type={"primary"}
              loading={isSubmitting}
              onClick={handleSubmit}
              disabled={isSubmitting || !isValid}
            >
              Зарєєструватися
            </Button>
          </Flex>
        )}
      </Formik>
    </>
  );
};

export default CreateAccountForm;
