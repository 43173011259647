import { Divider } from "antd";
import React from "react";

import LoginForm from "../../forms/login";
import LoginButtons from "../login-buttons";

const LoginComponent = ({ error, onSubmit, isLoading }) => {
  return (
    <>
      <LoginForm error={error} onSubmit={onSubmit} isLoading={isLoading} />
      <LoginButtons />
      <Divider />
    </>
  );
};

export default LoginComponent;
