import { Avatar, Dropdown, Input } from "antd";
import { Moon } from "iconsax-react";
import { Link, useNavigate } from "react-router-dom";

import {
  useGetSelfProfileQuery,
  useLogoutMutation,
} from "../../../features/api/user-api";
import ROUTES from "../../../routing/routes";
import * as $vars from "../../../utils/constants/variables";
import { load, remove } from "../../../utils/helpers/local-storage";
import { Notifications } from "../../notifications/notifications";
import { NewPostModal } from "../../post/new-post-modal/new-post-modal";
import { LogoHeader } from "../logo/logo-header";
import styles from "./header.module.scss";
import { HeaderSearch } from "./search/search";

const { Search } = Input;

const Header = () => {
  const navigate = useNavigate();
  const { data } = useGetSelfProfileQuery(undefined, {
    skip: !load("accessToken"),
  });
  const [logoutTrigger] = useLogoutMutation();

  const items = [
    {
      label: `Привіт, ${data?.display_name}`,
      disabled: true,
      key: "0",
    },
    {
      label: "Мій профіль",
      onClick: () => navigate(ROUTES.profile),
      key: "1",
    },
    {
      label: "Налаштування",
      onClick: () => navigate(ROUTES.settings.main),
      key: "2",
    },
    {
      type: "divider",
    },
    {
      label: "Вихід",
      onClick: () => {
        remove("accessToken");
        logoutTrigger();
      },
      key: "3",
    },
  ];
  return (
    <div className={styles.headerWrap}>
      <div className={styles.logoContainer}>
        <Link to={"/"}>
          <LogoHeader />
        </Link>
      </div>
      <div className={styles.controlsContainer}>
        <HeaderSearch />
        <NewPostModal />
        <div className={styles.darkModeButton}>
          <Moon size="24" color={$vars.colors.grey} />
          {/*<Sun1 size="24" color="#FFFFFF" />*/}
        </div>
        {data?.id ? (
          <>
            <Notifications />
            <Dropdown
              className={styles.profileButton}
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Avatar
                  shape="square"
                  size="large"
                  icon={<img src={data?.profile_image?.small?.url} alt="" />}
                />
              </a>
            </Dropdown>
          </>
        ) : (
          <div className={styles.signInContainer}>
            <Link
              to={"/start"}
              onClick={() => {
                remove("accessToken");
              }}
            >
              Sign in
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
