import img from "@/assets/images/no-posts.webp";
import { Link } from "react-router-dom";

import styles from "./profile-no-posts.module.scss";

const ProfileNoPosts = () => {
  return (
    <div className={styles.noPosts}>
      <div>
        <img src={img} alt="No posts" />
      </div>
      <div className={styles.noPostsTitle}>
        У користувача <Link to={"#"}>@byduck11111</Link> ще немає постів
      </div>
    </div>
  );
};

export { ProfileNoPosts };
