import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";

import {
  fetchNextPosts,
  fetchPosts,
  fetchRemovePost,
  fetchUpdateVote,
} from "../features/posts/posts-actions";
import {
  isFetchingNextProfilePosts,
  selectHasMoreProfilePosts,
  selectProfileError,
  selectProfileIsLoading,
  selectProfilePostLastId,
  selectProfilePosts,
} from "../features/posts/posts-slice";
import ROUTES from "../routing/routes";

const useProfilePosts = ({ profile_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const posts = useSelector(selectProfilePosts);
  const post_last_id = useSelector(selectProfilePostLastId);
  const hasMorePosts = useSelector(selectHasMoreProfilePosts);
  const isFetchingNextPage = useSelector(isFetchingNextProfilePosts);
  const error = useSelector(selectProfileError);
  const isLoading = useSelector(selectProfileIsLoading);
  useEffect(() => {
    if (profile_id && !posts.length) {
      dispatch(
        fetchPosts({ from_id: null, source: "profilePosts", profile_id }),
      );
    }
  }, [profile_id]);

  const getNextPosts = () => {
    if (post_last_id) {
      dispatch(
        fetchNextPosts({
          from_id: post_last_id,
          source: "profilePosts",
          profile_id,
        }),
      );
    }
  };

  const onComment = ({ id }) => {
    navigate(generatePath(ROUTES.post, { id }), {
      state: { scrollPos: window.scrollY },
    });
  };

  const onPostAction = useCallback(
    ({ id, type, metadata }) => {
      switch (type) {
        case "comment": {
          onComment({ id });
          break;
        }
        case "vote": {
          const { type: voteType } = metadata;
          dispatch(fetchUpdateVote({ postId: id, voteType }));
          break;
        }
        case "remove": {
          dispatch(fetchRemovePost({ postId: id }));
          break;
        }
        default:
          break;
      }
    },
    [dispatch],
  );

  return {
    posts,
    isLoading,
    error,
    onPostAction,
    getPosts: getNextPosts,
    hasMorePosts: hasMorePosts,
    isFetchingNextPage,
  };
};

export default useProfilePosts;
