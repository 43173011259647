import { Button, Dropdown, Space } from "antd";
import React, { useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

const actions = {
  follow: {
    key: "follow",
    label: "Підписатись",
  },
  unfollow: {
    key: "unfollow",
    label: "Відписатись",
  },
  block: {
    key: "block",
    label: "Заблокувати",
  },
  unblock: {
    key: "unblock",
    label: "Розблокувати",
  },
};

const FollowButton = ({ relations, onClick }) => {
  // Memoize the dropdown menu items for block/unblock actions
  const menuItems = useMemo(() => {
    if (!relations?.block) {
      return [actions.block];
    }
    return [];
  }, [relations]);

  // Determine the label for the follow/unfollow button
  const buttonLabel = useMemo(() => {
    if (relations?.block) {
      return "Розблокувати";
    }
    return relations?.follow ? "Відписатись" : "Підписатись";
  }, [relations]);

  // Handle click events for the follow/unfollow button
  const onButtonClick = () => {
    if (relations?.block) {
      onClick({ key: actions.unblock.key });
    } else if (relations?.follow) {
      onClick({ key: actions.unfollow.key });
    } else {
      onClick({ key: actions.follow.key });
    }
  };

  return (
    <Space>
      <Button
        type={relations?.follow && !relations?.block ? "default" : "primary"}
        size="large"
        onClick={onButtonClick}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 4,
        }}
      >
        <span>{buttonLabel}</span>
      </Button>
      <Dropdown
        arrow
        placement={"bottomRight"}
        menu={{
          items: menuItems,
          onClick: onClick,
        }}
        trigger={["hover"]}
      >
        <Button
          size="large"
          type="text"
          icon={<BsThreeDotsVertical size={20} />}
        />
      </Dropdown>
    </Space>
  );
};

export { FollowButton };
