import { useUpdateSinglePostMutation } from "@/features/api/posts-api";
import { fetchUpdatePost } from "@/features/posts/posts-actions";
import ROUTES from "@/routing/routes";
import { getPostForEdit } from "@/services/posts-service";
import { Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";

import AddNewPostForm from "../../forms/add-new-post/add-new-post-form";
import { Spinner } from "../../ui/spinner";

const EditPostModal = ({ postId, onCancel }) => {
  const dispatch = useDispatch();
  const [updateSingle] = useUpdateSinglePostMutation();
  const [postData, setPostData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const isPostPage = useMatch(ROUTES.post);

  const getPost = async () => {
    setIsLoading(true);
    const post = await getPostForEdit(postId);
    setIsLoading(false);
    setPostData(post.data);
  };

  const onSave = async ({ data }) => {
    try {
      if (isPostPage) {
        await updateSingle({ postId, data: data }).then((res) => {
          onCancel();
          return res;
        });
      } else {
        await dispatch(fetchUpdatePost({ id: postId, body: data })).then(
          (res) => {
            onCancel();
            return res;
          },
        );
      }
    } catch (e) {
      console.log("onSave error", e);
    }
  };

  useEffect(() => {
    getPost();
  }, []);

  return (
    <Modal
      style={{ padding: "0" }}
      open={true}
      width={768}
      title={false}
      footer={null}
      centered
      onCancel={onCancel}
      closeIcon={false}
      wrapClassName={"new-post-modal"}
    >
      {isLoading && <Spinner />}
      {!isLoading && (
        <AddNewPostForm post={postData} onCancel={onCancel} onSubmit={onSave} />
      )}
    </Modal>
  );
};

export { EditPostModal };
