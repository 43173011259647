import { configureStore } from "@reduxjs/toolkit";

import { api } from "./api/api";
import { reducer as UserReducer } from "./auth/user-slice";
import PostSlice from "./post/post-slice";
import PostsSlice from "./posts/posts-slice";
import userSettingsSlice from "./user-settings/user-settings-slice";

export const store = configureStore({
  reducer: {
    user: UserReducer,
    settings: userSettingsSlice,
    post: PostSlice,
    posts: PostsSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
