import { InfoCircle } from "iconsax-react";

import styles from "./form-error.module.scss";

const FormError = ({ children }) => {
  return (
    <div className={styles.errorMsg}>
      <InfoCircle size="12" color="#F2525B" />
      {children}
    </div>
  );
};

export default FormError;
