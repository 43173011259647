import { Navigate, Outlet, generatePath } from "react-router-dom";

import { hasJWT } from "../utils/helpers/utils";
import ROUTES from "./routes";

const AssignedUserRoute = ({
  redirectPath = generatePath(ROUTES.start, { type: "login" }),
}) => {
  if (!hasJWT()) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default AssignedUserRoute;
