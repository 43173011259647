import React from "react";

import styles from "./profile-backdrop.module.scss";

const ProfileBackdrop = ({ imgSrc }) => {
  return (
    <div className={styles.wrapper}>
      <img src={imgSrc} alt={"bg"} height={222} />
    </div>
  );
};

export { ProfileBackdrop };
