import { Spin } from "antd";
import React from "react";

import styles from "./spinner.module.scss";

const Spinner = () => {
  return (
    <div className={styles.wrapper}>
      <Spin />
    </div>
  );
};

export { Spinner };
