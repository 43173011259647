import React from "react";

import { PostList } from "../../components/post/posts-list";
import { Spinner } from "../../components/ui/spinner";
import useFeedPosts from "../../hooks/use-feed-posts";
import styles from "./feed.module.scss";

const Feed = () => {
  const {
    posts,
    error,
    onPostAction,
    getPosts,
    hasMorePosts,
    isFetchingNextPage,
  } = useFeedPosts();
  return (
    <div className={styles.feedWrap}>
      <PostList
        posts={posts}
        onAction={onPostAction}
        getPosts={getPosts}
        hasMorePosts={hasMorePosts}
      />
      {isFetchingNextPage && <Spinner />}
      {error?.length && <div>{error}</div>}
    </div>
  );
};

export { Feed };
