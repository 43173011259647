import { ReactComponent as Upvote } from "@/assets/icons/reactions/upvote.svg";
import { votesActions } from "@/utils/constants/options";
import { Flex } from "antd";
import React, { useRef } from "react";
import { useDebouncedCallback } from "use-debounce";

import styles from "./votes.module.scss";

const Votes = ({ onVote, votesCount, activeVote }) => {
  const [open, setOpen] = React.useState(false);

  const ref = useRef(null);

  const debouncedOpen = useDebouncedCallback(() => {
    setOpen(true);
  }, 600);

  const handlOnMouseLeave = useDebouncedCallback(() => {
    setOpen(false);
    debouncedOpen?.cancel();
  }, 300);

  const onFastVote = (vote) => {
    if (!open && !activeVote) {
      onVote(vote);
    }
    if (!open && activeVote) {
      onVote("");
    }
    debouncedOpen?.cancel();
  };

  const onOpenModalVote = (type) => {
    if (activeVote === type) {
      onVote("");
    } else {
      onVote(type);
    }
    setOpen(false);
  };
  return (
    <Flex
      className={styles.actionButton}
      style={{
        ...(!open && {
          backgroundColor: votesActions.find((vote) => vote.type === activeVote)
            ?.bgColor,
        }),
      }}
    >
      <div
        className={styles.wrapper}
        ref={ref}
        onClick={() => onFastVote("upvote")}
        onPointerEnter={debouncedOpen}
        onPointerLeave={handlOnMouseLeave}
      >
        {!activeVote && !open && (
          <div className={styles.actionMainIcon}>
            <Upvote />
          </div>
        )}
        {!open && activeVote && (
          <div
            className={styles.actionIcon}
            style={{
              backgroundColor: votesActions.find(
                (vote) => vote.type === activeVote,
              )?.btnColor,
            }}
          >
            {votesActions.find((vote) => vote.type === activeVote)?.iconActive}
          </div>
        )}
        {open &&
          votesActions.map((vote) => (
            <div
              className={styles.actionIcon}
              key={vote.type}
              onClick={() => onOpenModalVote(vote.type)}
            >
              {vote.icon}
            </div>
          ))}
      </div>
      {!open &&
        <div
          className={styles.actionLabel}
          style={{color: votesActions.find((vote) => vote.type === activeVote)?.lblColor}}
        >
          {votesCount}
        </div>
      }
    </Flex>
  );
};

export default Votes;
