import { Button, Checkbox, Flex, Input, Tooltip, notification } from "antd";
import { Field, Form, Formik } from "formik";
import { Paperclip2 } from "iconsax-react";
import React, { useEffect, useRef } from "react";

import {
  getPreSignedUrl,
  uploadToPresignedUrl,
} from "../../../services/upload-service";
import * as vars from "../../../utils/constants/variables";
import { getAllErrors } from "../../../utils/helpers/string-transform";
import styles from "./add-new-post-form.module.scss";
import UploadedImage from "./components/uploaded-image";

const AddNewPostForm = ({ onCancel, post, onSubmit }) => {
  const [attachments, setAttachments] = React.useState([]);
  const uploadInputRef = React.useRef(null);
  const [notificationApi, contextHolder] = notification.useNotification();
  const formRef = useRef(null);
  const [dragOver, setDragOver] = React.useState(false);

  const onUploadPercentChange = (id, progress) => {
    setAttachments((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            process: progress,
          };
        }
        return item;
      });
    });
  };

  const createAttachmentStateItem = async (file) => {
    try {
      const name = file.name;
      const { url, fields } = await getPreSignedUrl(name);
      setAttachments((prev) => [
        ...prev,
        {
          id: fields.key,
          file,
          process: 0,
        },
      ]);

      uploadToPresignedUrl(url, file, fields, (progress) =>
        onUploadPercentChange(fields.key, progress),
      );
    } catch (e) {
      getAllErrors(e.response.data).forEach((item) => {
        notificationApi.error({
          message: item,
        });
      });
    }
  };

  const onAddFile = async (e) => {
    const files = e.target.files;
    for await (const file of files) {
      await createAttachmentStateItem(file);
    }
    e.target.value = null;
  };

  const onUploadClick = () => {
    uploadInputRef.current.click();
  };

  const handleSubmit = (values, { resetForm }) => {
    const data = {
      ...values,
      files: attachments.map((item) => item.id),
    };
    onSubmit({ data })
      .then(() => {
        resetForm();
        setAttachments([]);
      })
      .catch((error) => {
        getAllErrors(error).forEach((item) => {
          notificationApi.error({
            message: item,
          });
        });
      });
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    let handled = false;

    for (const item of items) {
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          createAttachmentStateItem(file);
          handled = true;
        }
      }
    }

    if (!handled) {
      const pastedText = event.clipboardData.getData("text/plain");
      if (pastedText && !formRef?.current?.values?.body) {
        formRef.current.setFieldValue("body", pastedText);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "Enter") {
        if (formRef.current) {
          formRef.current.submitForm();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const files = e.dataTransfer.files;
    for (const file of files) {
      createAttachmentStateItem(file);
    }
  };

  return (
    <>
      {contextHolder}
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          border: dragOver
            ? `2px dashed ${vars.colors.primary}`
            : "2px dashed transparent",
          backgroundColor: dragOver ? "#f0f9ff" : "transparent",
          transition: "border 0.2s ease, background-color 0.2s ease",
          padding: "5px",
        }}
      >
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            body: post?.body || "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <div className={styles.formPostWrap}>
                <Field
                  name="body"
                  render={({ field }) => (
                    <Input.TextArea
                      className={styles.formTextarea}
                      {...field}
                      autoSize={true}
                      placeholder="Створіть новий допис"
                    />
                  )}
                />
                <div className={styles.attachments}>
                  <input
                    ref={uploadInputRef}
                    type="file"
                    onChange={onAddFile}
                    multiple
                    style={{ display: "none" }}
                  />
                  {attachments.map((attachment) => (
                    <div className="handle" key={attachment.id}>
                      <UploadedImage
                        process={attachment.process}
                        image={attachment}
                        onRemove={(image) =>
                          setAttachments((prev) =>
                            prev.filter((item) => item.id !== image.id),
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.formFooter}>
                <Flex align="center" gap={16}>
                  <Button
                    type="default"
                    size="large"
                    onClick={onUploadClick}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "12px",
                    }}
                  >
                    <Paperclip2 size={16} />
                  </Button>
                  <Checkbox onChange={(event) => event.preventDefault()}>
                    Для друзів
                  </Checkbox>
                </Flex>
                <div className={styles.formFooterButtons}>
                  <Button type="default" size="large" onClick={onCancel}>
                    Відмінити
                  </Button>
                  <Tooltip title="Ctrl + Enter">
                    <Button
                      disabled={!values.body && attachments.length === 0}
                      type="primary"
                      size="large"
                      htmlType={"submit"}
                    >
                      Опублікувати
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddNewPostForm;
