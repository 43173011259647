export const getStartPageTitle = (type) => {
  switch (type) {
    case "sign-up":
      return "Створити акаунт";
    case "remind":
      return "Забули пароль?";
    case "login":
    default:
      return "Вхід до Vmist!";
  }
};

export function getAllErrors(json) {
  let messages = [];

  function traverse(obj) {
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item) => {
          if (typeof item === "string") {
            messages.push(item);
          } else if (typeof item === "object") {
            traverse(item);
          }
        });
      } else if (typeof obj[key] === "object") {
        traverse(obj[key]);
      }
    }
  }

  traverse(json);
  return messages;
}

export function checkPasswordRequirements(password) {
  const hasDigit = /\d/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasSymbol = /[^a-zA-Z0-9]/.test(password);
  const hasMinimumLength = password.length >= 8;

  return {
    hasDigit: hasDigit,
    hasLowerCase: hasLowerCase,
    hasUpperCase: hasUpperCase,
    hasSymbol: hasSymbol,
    hasMinimumLength: hasMinimumLength,
  };
}
