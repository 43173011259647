import { Flex } from "antd";

import ThreadComment from "./thread-comment";
import ThreadItem from "./thread-item";

const Comments = ({ comments, onAction, parentId, comments_left }) => {
  return (
    <Flex vertical gap={12}>
      {comments?.length > 0 &&
        !parentId > 0 &&
        comments.map((comment) => (
          <ThreadItem
            parentId={parentId}
            key={comment.id}
            comment={comment}
            onAction={onAction}
          />
        ))}
      {comments?.length > 0 &&
        parentId > 0 &&
        comments.map((comment, index) => (
          <ThreadComment
            parentId={parentId}
            key={comment.id}
            comment={comment}
            onAction={onAction}
            isLast={comments_left === 0 && index === comments?.length - 1}
          />
        ))}
    </Flex>
  );
};

export { Comments };
