import { Button, Form, Input } from "antd";
import { Send2 } from "iconsax-react";
import React, { useState } from "react";

import styles from "./add-comment-form.module.scss";

const AddCommentForm = ({ onPostComment, autoFocus = true }) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    onPostComment(value).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        setValue("");
      }
    });
  };

  const onEnterPress = (e) => {
    if (!e.shiftKey) {
      handleSubmit();
    }
  };

  return (
    <Form className={styles.wrapper}>
      <Input.TextArea
        value={value}
        size={"middle"}
        autoSize={true}
        autoFocus={autoFocus}
        onChange={handleChange}
        onPressEnter={onEnterPress}
        className={styles.commentForm}
        placeholder="Написати коментар"
      />

      <div className={styles.formSubmit}>
        <Button
          type="link"
          size={"large"}
          icon={<Send2 size="24" color="#717183" />}
          onClick={handleSubmit}
        />
      </div>
    </Form>
  );
};

export default AddCommentForm;
