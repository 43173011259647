import { Flex, Skeleton } from "antd";
import { LuUser2 } from "react-icons/lu";

import { ProfileBackdrop } from "./components/profile-backdrop";
import { ProfileStats } from "./components/profile-stats";
import styles from "./profile-header.module.scss";

const ProfileHeader = ({
  profile,
  isLoading,
  topRightComponent,
  bioComponent,
  onAvatarClick,
}) => {
  if (isLoading)
    return (
      <div className={styles.card}>
        <Skeleton avatar paragraph={{ rows: 3 }} />
      </div>
    );

  const profileAvatar = profile?.profile_image?.medium?.url;

  return (
    <div className={styles.card}>
      <ProfileBackdrop imgSrc={profile?.cover_image?.large?.url} />
      <div className={styles.profileBottom}>
        <div className={styles.profilePhoto}>
          {profileAvatar ? (
            <img
              onClick={onAvatarClick}
              src={profileAvatar}
              alt={"avatar"}
              height={156}
            />
          ) : (
            <LuUser2 />
          )}
        </div>
        <div style={{ flex: "1" }}>
          <div className={styles.profileBody}>
            <Flex vertical gap={6}>
              <div className={styles.profileName}>{profile?.display_name}</div>
              <ProfileStats
                follows_count={profile?.followers_count}
                posts_count={profile?.posts_count}
              />
            </Flex>
            <Flex gap={4}>{topRightComponent}</Flex>
          </div>
        </div>
      </div>
      {bioComponent}
    </div>
  );
};

export default ProfileHeader;
