import actionCable from "actioncable";
import React, { createContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { selectUsersResult } from "../../features/api/user-api";
import { load } from "../helpers/local-storage";

export const CableContext = createContext(null);

const CableProvider = ({ children }) => {
  const token = load("accessToken");
  const userId = useSelector(selectUsersResult)?.data?.id;

  const cable = actionCable.createConsumer(
    `wss://dev.vmist.app/websocket?token=${token}`,
  );

  useEffect(() => {
    return () => {
      cable.disconnect();
    };
  }, [token, userId]);
  return (
    <CableContext.Provider value={cable}>{children}</CableContext.Provider>
  );
};

export default CableProvider;
