import { Typography } from "antd";
import { Lock1 } from "iconsax-react";
import React from "react";

import styles from "./blocked-container.module.scss";

const BlockedContainer = () => {
  return (
    <div className={styles.wrapper}>
      <Lock1 size="32" color="#6F6AF5" />
      <Typography.Paragraph
        className={styles.text}
        style={{
          textAlign: "center",
        }}
      >
        Ви заблокували цього користувача. Щоб переглянути стрічку та продовжити
        взаємодію із ним, ви можете його розблокувати
      </Typography.Paragraph>
    </div>
  );
};

export { BlockedContainer };
