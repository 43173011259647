import ROUTES from "@/routing/routes";
import { Link, generatePath } from "react-router-dom";

import styles from "./sign-up-type-links.module.scss";

const signUpTypeLinks = {
  ["sign-up"]: (
    <div className={styles.bottomLinks}>
      В мене вже є акаунт?
      <Link
        className={styles.link}
        to={generatePath(ROUTES.start, { type: "login" })}
      >
        <strong> Увійти</strong>
      </Link>
    </div>
  ),
  remind: (
    <div className={styles.bottomLinks}>
      <Link
        className={styles.link}
        to={generatePath(ROUTES.start, { type: "login" })}
      >
        <strong>Я згадав пароль</strong>
      </Link>
    </div>
  ),
  login: (
    <div className={styles.bottomLinks}>
      <div>
        Немає акаунта?
        <Link
          className={styles.link}
          to={generatePath(ROUTES.start, { type: "sign-up" })}
        >
          <strong>Зарєєструйтесь</strong>
        </Link>
      </div>
    </div>
  ),
};

export { signUpTypeLinks };
