import { SearchNormal1 } from "iconsax-react";

import * as $vars from "../../../../utils/constants/variables";
import styles from "./search.module.scss";

const HeaderSearch = () => {
  return (
    <div className={styles.searchButton}>
      <SearchNormal1 size="24" color={$vars.colors.grey} />
    </div>
  );
};

export { HeaderSearch };
