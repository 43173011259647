import React from "react";
import { useNavigate } from "react-router-dom";

import { useLoginMutation } from "../../../features/api/user-api";
import ROUTES from "../../../routing/routes";

const useLogin = () => {
  const navigate = useNavigate();

  const [login, { isLoading: isLoginLoading, error: loginError }] =
    useLoginMutation();

  const handleLogin = (data) => {
    return login(data)
      .unwrap()
      .then(() => {
        navigate(ROUTES.home);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  return {
    handleLogin,
    loginError,
    isLoginLoading,
  };
};

export default useLogin;
