import { selfProfileModalConfig } from "@/utils/constants/integers";
import { selfProfileActionsValues as selfProfileValues } from "@/utils/constants/options";
import { Modal } from "antd";
import React from "react";

import { ChangeAvatarOrBackground } from "./components/change-avatar";
import styles from "./profile-modals.module.scss";

const ProfileModals = ({ activeModal, onClose }) => {
  if (
    activeModal === selfProfileValues.edit_avatar ||
    activeModal === selfProfileValues.edit_cover
  ) {
    return (
      <Modal
        open={true}
        width={470}
        centered
        maskClosable={false}
        title={selfProfileModalConfig[activeModal].uploadButtonText}
        footer={null}
        onCancel={onClose}
        modalRender={(render) => {
          return <div className={styles.modalContentWrapper}>{render}</div>;
        }}
      >
        <ChangeAvatarOrBackground
          onSuccess={onClose}
          attachmentType={selfProfileModalConfig[activeModal].attachment_type}
          initialCropState={
            selfProfileModalConfig[activeModal].initialCropState
          }
          imageRatio={selfProfileModalConfig[activeModal].imageRatio}
          minHeight={selfProfileModalConfig[activeModal].minHeight}
          imageDirection={selfProfileModalConfig[activeModal].imageDirection}
        />
      </Modal>
    );
  }

  return null;
};
export { ProfileModals };
