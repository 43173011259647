import { getScrollKey } from "@/utils/helpers/utils";
import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

const ScrollRestorationComponent = () => {
  return (
    <>
      <ScrollRestoration getKey={getScrollKey} />
      <Outlet />
    </>
  );
};

export { ScrollRestorationComponent };
