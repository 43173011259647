import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { PostCard } from "../post-card";
import styles from "./post-list.module.scss";

const PostList = ({ posts = [], onAction, getPosts, hasMorePosts }) => {
  return (
    <InfiniteScroll
      initialLoad={false}
      loadMore={getPosts}
      hasMore={hasMorePosts}
      useWindow={true}
      threshold={200}
    >
      <div className={styles.list}>
        {posts?.length > 0 &&
          posts.map((post) => (
            <PostCard key={post.id} post={post} onAction={onAction} hasShadow={true} />
          ))}
      </div>
    </InfiniteScroll>
  );
};

export { PostList };
