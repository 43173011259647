import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchCommentVote,
  fetchComments,
  fetchNextThreads,
  fetchPostComment,
  fetchRemoveComment,
  fetchThreadsForPost,
} from "../../../features/post/post-actions";

export const usePostComments = ({ id }) => {
  const dispatch = useDispatch();
  const {
    data,
    isLoading,
    comment_last_id,
    isLoadingNext,
    comments_left,
    error,
  } = useSelector((state) => state.post.comments);

  const getComments = () => {
    dispatch(fetchNextThreads({ id, from_id: comment_last_id }));
  };

  const onAddNewThread = async ({ body, postId }) => {
    const res = await dispatch(fetchPostComment({ postId, body }));
    return res;
  };

  const onAddCommentToThread = async ({ postId, body, commentId }) => {
    const res = await dispatch(
      fetchPostComment({
        postId,
        body,
        comment_id: commentId,
      }),
    );
    return res;
  };

  const handleLoadNextThreadComments = ({ fromId, postId, parentId }) => {
    dispatch(
      fetchComments({
        from_id: fromId,
        post_id: postId,
        parent_id: parentId,
      }),
    );
  };

  const handleCommentRemove = async ({ comment_id, thread_id, post_id }) => {
    const res = await dispatch(
      fetchRemoveComment({ comment_id, thread_id, post_id }),
    );
    return res;
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchThreadsForPost({ id }));
    }
  }, [id]);

  return {
    threadLastId: comment_last_id,
    data,
    isLoading: isLoading,
    isLoadingNext,
    error,
    commentsLeft: comments_left,
    getComments,
    onAddNewThread,
    onAddCommentToThread,
    handleLoadNextThreadComments,
    handleCommentRemove,
    fetchCommentVote: (...data) => dispatch(fetchCommentVote(...data)),
    fetchRemoveComment: (...data) => dispatch(fetchRemoveComment(...data)),
  };
};
