import { Flex, List, Typography } from "antd";
import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../../routing/routes";
import styles from "./privacy.module.scss";

const SettingsPrivacy = () => {
  const navigate = useNavigate();
  return (
    <List className={styles.wrapSettings}>
      <Flex>
        <div className={styles.backButtonWrap}>
          <div
            className={styles.backButton}
            onClick={() => navigate(ROUTES.settings.main)}
          >
            <div>
              <BiArrowBack />
            </div>
          </div>
        </div>
        <Typography.Title className={styles.settingsH1} level={3}>
          Конфіденційність та безпека
        </Typography.Title>
      </Flex>
      <div className={styles.settingsCard}>form fields</div>
    </List>
  );
};

export { SettingsPrivacy };
