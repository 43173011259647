import * as vars from "@/utils/constants/variables";
import React from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

import { Logo } from "../../ui/logo/logo-auth";
import { headerNavLinks } from "./constants/headerNavLinks";
import styles from "./header.module.scss";

const Header = () => (
  <>
    <div className={styles.headerWrap}>
      <Logo />
      <MediaQuery minWidth={vars.resolutions.md}>
        <div className={styles.headerNav}>
          {headerNavLinks.map((item) => (
            <Link className={styles.headerNavItem} key={item.key} to={item.url}>
              {item.label}
            </Link>
          ))}
        </div>
      </MediaQuery>
    </div>
  </>
);

export default Header;
