import ROUTES from "@/routing/routes";
import cn from "@/utils/helpers/className";
import { formatDateTemplate } from "@/utils/helpers/date";
import { Avatar, Flex, Popover, Tag, Tooltip } from "antd";
import { format } from "date-fns";
import { AddSquare, MinusSquare } from "iconsax-react";
import React, { memo, useState } from "react";
import { LuUser2 } from "react-icons/lu";
import { Link, generatePath } from "react-router-dom";

import AddCommentForm from "../../forms/add-comment-form/add-comment-form";
import PopoverUser from "../../ui/popovers/popover-user";
import { Spinner } from "../../ui/spinner";
import { Comments } from "../comments";
import CommentsActions from "../comments-actions";
import { sortArrayWithPostDataToEnd } from "../constants";
import styles from "./thread-item.module.scss";

const ThreadItem = ({
  comment: {
    id,
    body,
    votes_up,
    votes_down,
    reposts_count,
    comments_count,
    profile,
    published_at_str,
    comments,
    isLoading,
    comment_last_id,
    comments_left,
    error,
    published_at,
    vote,
    votes_sum,
    permissions,
  },
  onAction,
  parentId,
}) => {
  const { display_name, username, profile_image } = profile;
  const [isExpanded, setIsExpanded] = useState(true);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAction = (type, metadata) => {
    if (type === "comment") {
      if (!isCommentFormOpen) {
        setIsExpanded(true);
      }
      setIsCommentFormOpen(!isCommentFormOpen);
    }
    return onAction({
      type,
      id,
      parentId,
      metadata,
    })?.then((res) => {
      if (type === "reply" && res?.payload?.id) {
        setIsCommentFormOpen(false);
      }
    });
  };
  return (
    <Flex className={styles.threadWrap} vertical gap={12}>
      <div className={styles.firstWrap}>
        {comments?.length > 0 && (
          <>
            <div
              className={cn(
                styles.FirstLevelLine,
                isExpanded ? "isExpanded" : "",
              )}
            />
            <div
              className={cn(styles.btnCollapse, isExpanded ? "isExpanded" : "")}
              onClick={handleExpand}
            >
              {isExpanded ? (
                <MinusSquare size="20" color="#91919d" />
              ) : (
                <AddSquare size="20" color="#91919d" />
              )}
            </div>
          </>
        )}
        <Flex gap={8}>
          <Link
            to={generatePath(ROUTES.otherProfile, { slag: username })}
            style={{ height: "40px" }}
          >
            <Avatar
              style={{ outline: "4px solid white", border: "none" }}
              shape="square"
              size="large"
              icon={
                profile_image?.small?.url ? (
                  <img
                    src={profile_image?.small?.url}
                    alt={"avatar"}
                    height={40}
                  />
                ) : (
                  <LuUser2 />
                )
              }
            />
          </Link>
          <Flex vertical gap={2} flex={1}>
            <Flex className={styles.commentHeader} align={"center"}>
              <Link
                className={styles.commentAuthor}
                to={generatePath(ROUTES.otherProfile, { slag: username })}
              >
                <Popover
                  placement="bottomLeft"
                  content={<PopoverUser slug={username} />}
                  title={null}
                  className={"user-popover"}
                  style={{ width: "350px" }}
                >
                  {display_name}
                </Popover>
              </Link>
              <Tooltip
                title={
                  published_at?.length &&
                  format(published_at, formatDateTemplate)
                }
              >
                <Link
                  className={styles.commentDateTime}
                  to={generatePath(ROUTES.otherProfile, { slag: username })}
                >
                  {published_at_str}
                </Link>
              </Tooltip>
            </Flex>
            <div className={styles.commentBody}>{body}</div>
            <Flex
              justify={"space-between"}
              align={"center"}
              style={{ marginLeft: "-4px" }}
            >
              <CommentsActions
                permissions={permissions}
                onAction={handleAction}
                votesCount={votes_sum}
                activeVote={vote?.vote_type}
                counters={{
                  id,
                  votes_up,
                  votes_down,
                  reposts_count,
                  comments_count,
                }}
              />
            </Flex>
            {isCommentFormOpen && (
              <div style={{ marginTop: "8px" }}>
                <AddCommentForm
                  onPostComment={(commentData) =>
                    handleAction("reply", commentData)
                  }
                />
              </div>
            )}
          </Flex>
        </Flex>
      </div>
      {comments?.length > 0 && isExpanded && (
        <div className={styles.threadCommentsWrap}>
          <Comments
            comments={sortArrayWithPostDataToEnd(comments)}
            onAction={onAction}
            parentId={id}
            comments_left={comments_left}
          />
        </div>
      )}
      {isLoading && (
        <div style={{ marginTop: "16px" }}>
          <Spinner />
        </div>
      )}
      {!isLoading && isExpanded && comments_left > 0 && (
        <Flex className={styles.moreWrap} align="center" gap={20}>
          <div
            className={styles.btnExpand}
            onClick={() =>
              handleAction("preload_comments", { comment_last_id })
            }
          >
            <AddSquare size="20" color="#91919d" />
          </div>
          <div
            className={styles.threadMoreComments}
            onClick={() =>
              handleAction("preload_comments", { comment_last_id })
            }
          >
            Ще {comments_left} комментарів
          </div>
        </Flex>
      )}
      {error && <div>{error}</div>}
    </Flex>
  );
};

const memoizedThreadItem = memo(ThreadItem);

export { memoizedThreadItem as ThreadItem };
