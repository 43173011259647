import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectUsersResult } from "../../features/api/user-api";
import { fetchPostById } from "../../features/posts/posts-actions";
import { useCable } from "../../hooks/useCable";
import { load } from "../helpers/local-storage";

const PostsUpdateProvider = ({ children }) => {
  const cable = useCable();

  const userId = useSelector(selectUsersResult)?.data?.id;
  const token = load("accessToken");

  const dispatch = useDispatch();
  const onReceived = (data) => {
    switch (data.event) {
      case "published":
        dispatch(fetchPostById({ id: data.post_id }));
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (token && userId) {
      cable.subscriptions.create(
        {
          channel: "PostChannel",
        },
        {
          received: onReceived,
        },
      );
    }
  }, [token, userId]);

  return children;
};

export default PostsUpdateProvider;
