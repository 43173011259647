import { fetchCreatePost } from "@/features/posts/posts-actions";
import routes from "@/routing/routes";
import * as $vars from "@/utils/constants/variables";
import { Modal } from "antd";
import { AddSquare } from "iconsax-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";

import AddNewPostForm from "../../forms/add-new-post/add-new-post-form";
import styles from "./new-post-modal.module.scss";

const NewPostModal = () => {
  const dispatch = useDispatch();
  const isProfile = useMatch(routes.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onCreatePost = async ({ data }) => {
    try {
      const source = isProfile ? "profilePosts" : "feedPosts";
      await dispatch(fetchCreatePost({ body: data, source }))
        .unwrap()
        .then((res) => {
          setIsModalOpen(false);
          return res;
        });
    } catch (e) {
      console.log("onCreatePost error", e);
    }
  };

  return (
    <>
      <div className={styles.newPostButton} onClick={showModal}>
        <AddSquare size="24" color={$vars.colors.grey} />
      </div>
      <Modal
        open={isModalOpen}
        width={1134}
        title={false}
        header={null}
        footer={null}
        centered
        onCancel={handleCancel}
        closeIcon={false}
        wrapClassName={"new-post-modal"}
      >
        <AddNewPostForm onCancel={handleCancel} onSubmit={onCreatePost} />
      </Modal>
    </>
  );
};

export { NewPostModal };
