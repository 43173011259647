import { api } from "./api";

export const postsApi = api.injectEndpoints({
  tagTypes: ["post"],
  endpoints: (builder) => ({
    getPostById: builder.query({
      query: (id) => ({
        url: `/posts/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, postId) => [{ type: "post", id: postId }],
    }),
    updateVoteOnSinglePost: builder.mutation({
      query: ({ postId, voteType }) => ({
        url: `/posts/${postId}/votes`,
        method: "POST",
        body: { vote_type: voteType },
      }),
      async onQueryStarted({ postId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedVote } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData("getPostById", postId, (draft) => {
              draft.vote = updatedVote;
              draft.votes_sum = draft.votes_sum + updatedVote.vote_delta;
            }),
          );
        } catch {
          console.log("err");
        }
      },
    }),
    updateSinglePost: builder.mutation({
      query: ({ postId, data }) => ({
        url: `/posts/${postId}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "post", id }],
    }),
    removePost: builder.mutation({
      query: (id) => ({
        url: `/posts/${id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPostByIdQuery,
  useUpdateVoteOnSinglePostMutation,
  useRemovePostMutation,
  useUpdateSinglePostMutation,
} = postsApi;
