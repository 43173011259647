import React from "react";

import styles from "./icon-button.module.scss";

const IconButton = ({ icon: Icon, title }) => {
  const handleClick = (event) => {
    event.preventDefault();
  };

  return (
    <div className={styles.button} onClick={handleClick}>
      <Icon className={styles.buttonIcon} />
      <span className={styles.buttonText}>{title}</span>
    </div>
  );
};

export default IconButton;
