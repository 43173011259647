import React from "react";

import CreateAccountForm from "../../forms/create-account";

const CreateAccount = ({ error, onSubmit }) => {
  return (
    <>
      <CreateAccountForm error={error} onSubmit={onSubmit} />
    </>
  );
};

export default CreateAccount;
