import axios from "axios";

import { load } from "../utils/helpers/local-storage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `${load("accessToken")}`;
  return config;
});

export default instance;
