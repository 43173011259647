import { Dropdown, Flex } from "antd";
import React, { useMemo } from "react";
import { BsThreeDots } from "react-icons/bs";

import { Votes } from "../../ui/votes";
import { POST_ACTION_TYPES, PostToolbarActions } from "../constants";
import styles from "./post-actions.module.scss";
import {ArrowForward, DocumentCopy} from "iconsax-react";

const PostActions = ({
  onAction,
  votesCount,
  activeVote,
  permissions,
  counters,
}) => {
  const dropdownItems = useMemo(() => {
    return [
      {
        key: POST_ACTION_TYPES.EDIT,
        label: "Редагувати",
        visible: permissions.can_edit,
      },
      {
        key: POST_ACTION_TYPES.REPORT,
        label: "Поскаржитись",
        visible: true,
      },
      {
        key: POST_ACTION_TYPES.REMOVE_REQUEST,
        label: "Видалити",
        danger: true,
        visible: permissions.can_delete,
      },
    ]
      .filter((item) => item.visible)
      .map(({ visible, ...rest }) => rest);
  }, [permissions]);

  const shareItems = [
    {
      key: '1',
      label: 'Зробити репост',
      icon: <ArrowForward />,
    },
    {
      key: '2',
      label: 'Скопіювати посилання',
      icon: <DocumentCopy/>,
    },
  ]

  return (
    <>
      <Flex className={styles.postFooter} align={"center"} gap={8}>
        <Votes
          onVote={(voteType) => onAction("vote", { type: voteType })}
          activeVote={activeVote}
          votesCount={votesCount}
        />
        <Flex gap={8} align={"center"}>
          {PostToolbarActions.map((action) => (
            <Flex
              className={styles.actionButton}
              align="center"
              key={action.type}
            >
              <div
                className={styles.actionIcon}
                onClick={() => onAction(action.type)}
              >
                {action.icon}
              </div>
              <div className={styles.actionLabel}>
                {counters[action.source]}
              </div>
            </Flex>
          ))}
          <Dropdown
            menu={{
              items: shareItems,
            }}
            placement={"top"}
            arrow
          >
            <div className={styles.footerButton}>
              <ArrowForward size={18}/>
            </div>
          </Dropdown>
          <Dropdown
            menu={{
              items: dropdownItems,
              onClick: (e) => onAction(e.key),
            }}
            placement="top"
            arrow
          >
            <div className={styles.footerButton}>
              <BsThreeDots size={18} />
            </div>
          </Dropdown>
        </Flex>
      </Flex>
    </>
  );
};

export { PostActions };
