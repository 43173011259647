import styles from "./image-more.module.scss";

const ImageMore = ({ moreCount }) => {
  return (
    <div className={styles.imageMore}>
      <div className={styles.imageMoreLabel}>+ {moreCount}</div>
    </div>
  );
};

export { ImageMore };
