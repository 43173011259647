import styles from "./profile-stats.module.scss";

const ProfileStats = ({ follows_count, posts_count }) => {
  return (
    <div className={styles.profileSubscribers}>
      <div className={styles.profileCount}>
        <strong>{follows_count}</strong> слідкують
      </div>
      <div className={styles.profileCount}>
        <strong>{posts_count}</strong> дописів
      </div>
    </div>
  );
};

export { ProfileStats };
