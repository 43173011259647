import ROUTES from "@/routing/routes";
import * as $vars from "@/utils/constants/variables";
import cn from "@/utils/helpers/className";
import { formatDateTemplate } from "@/utils/helpers/date";
import { Avatar, Flex, Popover, Tooltip, Typography } from "antd";
import { format } from "date-fns";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import React, { memo, useState } from "react";
import { LuUser2 } from "react-icons/lu";
import { Link, generatePath } from "react-router-dom";

import AddCommentForm from "../../forms/add-comment-form/add-comment-form";
import { RemoveModal } from "../../ui/modals/remove-modal";
import { ReportModal } from "../../ui/modals/report-modal";
import PopoverUser from "../../ui/popovers/popover-user";
import { POST_ACTION_TYPES } from "../constants";
import { EditPostModal } from "../edit-post-modal";
import PostActions from "../post-actions";
import PostImages from "../post-images/post-images";
import styles from "./post-card.module.scss";

const ReadMore = () => {
  return (
    <div className={styles.readMore}>
      <span className={styles.readMoreSpan}>
        Читати далі
        <ArrowDown2 size="16" color={$vars.colors.primaryColor} />
      </span>
    </div>
  );
};

const LongReadControls = () => {
  return (
    <div className={styles.longreadBar}>
      <span>
        <ArrowUp2 size="15" color="#7e7aff" />
        &nbsp; На початок
      </span>
      <span>
        У кінець&nbsp;
        <ArrowDown2 size="15" color="#7e7aff" />
      </span>
      <div className={styles.longreadBarProgress} style={{ width: "34%" }} />
    </div>
  );
};

const PostCard = ({
  post: {
    id,
    body,
    votes_up,
    votes_down,
    reposts_count,
    comments_count,
    profile,
    published_at_str,
    published_at,
    external_images,
    vote,
    permissions,
    views_count,
  },
  onAction,
  hasCommentForm,
  hasShadow,
}) => {
  const { display_name, profile_image, username } = profile;
  const [removeModalVisible, setRemoveModalVisible] = React.useState(false);
  const [reportModalVisible, setReportModalVisible] = React.useState(false);
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const onActionWithId = (type, metadata) => {
    if (type === POST_ACTION_TYPES.REMOVE_REQUEST) {
      setRemoveModalVisible(true);
    } else if (type === POST_ACTION_TYPES.EDIT) {
      setEditModalVisible(true);
    } else if (type === POST_ACTION_TYPES.REPORT) {
      setReportModalVisible(true);
    } else {
      return onAction({ id, type, metadata });
    }
  };
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      className={cn(
        styles.postWrap,
        published_at === null ? styles.notPublished : "",
      )}
      style={{
        ...(hasShadow && { boxShadow: $vars.boxShadow.lg }),
      }}
    >
      {editModalVisible && (
        <EditPostModal
          postId={id}
          onCancel={() => setEditModalVisible(false)}
        />
      )}
      {removeModalVisible && (
        <RemoveModal
          title={"Видалити"}
          text={"Ця дія невідворотня. Ви дійсно бажаєте видалити цей пост?"}
          onSubmit={() => onActionWithId("remove")}
          onCancel={() => setRemoveModalVisible(false)}
        />
      )}
      {reportModalVisible && (
        <ReportModal
          title={"Поскаржитись"}
          onSubmit={() => onActionWithId("remove")}
          onCancel={() => setReportModalVisible(false)}
        />
      )}
      <Flex vertical gap={10}>
        <Flex className={styles.postHeader} gap={8} align={"top"}>
          <Link to={generatePath(ROUTES.otherProfile, { slag: username })}>
            <Avatar
              shape="square"
              size="large"
              icon={
                profile_image?.small?.url ? (
                  <img
                    src={profile_image?.small?.url}
                    alt={"avatar"}
                    width={40}
                  />
                ) : (
                  <LuUser2 />
                )
              }
            />
          </Link>
          <Link
            className={styles.postAuthor}
            to={generatePath(ROUTES.otherProfile, { slag: username })}
          >
            <Popover
              placement="bottomLeft"
              content={<PopoverUser slug={username} />}
              title={null}
              className={"user-popover"}
              style={{ width: "350px" }}
            >
              {display_name}
            </Popover>
          </Link>
          <Tooltip
            title={
              published_at?.length && format(published_at, formatDateTemplate)
            }
          >
            <Link
              className={styles.postDateTime}
              to={generatePath(ROUTES.otherProfile, { slag: username })}
            >
              {published_at_str}
            </Link>
          </Tooltip>
        </Flex>
        {body && (
          <Typography.Paragraph
            className={cn(styles.postBody, "post-body")}
            ellipsis={{
              rows: 8,
              expandable: "collapsible",
              expanded,
              onExpand: () => setExpanded,
              symbol: <ReadMore />,
            }}
          >
            {body}
            {body.length > 4000 && <LongReadControls />}
          </Typography.Paragraph>
        )}
        {external_images?.length > 0 && (
          <PostImages
            images={external_images}
            post={{
              body,
              profile: { display_name, profile_image },
              published_at_str,
              published_at,
            }}
          />
        )}
        <PostActions
          permissions={permissions}
          activeVote={vote?.vote_type}
          votesCount={vote.vote_value}
          onAction={onActionWithId}
          counters={{
            id,
            votes_up,
            votes_down,
            reposts_count,
            comments_count,
            views_count,
          }}
        />
        {hasCommentForm && (
          <div className={styles.postComments}>
            <AddCommentForm
              autoFocus={false}
              onPostComment={(commentData) =>
                onActionWithId("reply", commentData)
              }
            />
          </div>
        )}
      </Flex>
    </div>
  );
};

const PostCardMemo = memo(PostCard);
export { PostCardMemo as PostCard };
