import React from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import LayoutAuth from "../components/layouts/auth/layout-auth";
import DefaultLayout from "../components/layouts/default";
import { ScrollRestorationComponent } from "../components/scroll-restoration";
import { ErrorPage } from "../pages/error";
import { Feed } from "../pages/feed";
import { OtherProfile } from "../pages/other-profile";
import { PostPage } from "../pages/post-page";
import { Profile } from "../pages/profile";
import { Settings } from "../pages/settings";
import { SettingsSite } from "../pages/settings/edit";
import { SettingsPremium } from "../pages/settings/premium";
import { SettingsPrivacy } from "../pages/settings/privacy";
import { SettingsProfile } from "../pages/settings/profile";
import { StartPage } from "../pages/start-page";
import AssignedUserRoute from "./AssignedUserRoute";
import ROUTES from "./routes";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTES.home} element={<ScrollRestorationComponent />}>
      <Route path={ROUTES.start} element={<LayoutAuth />}>
        <Route index element={<StartPage />} />
      </Route>

      {/* Unassigned users can view global feed and post by id */}
      <Route element={<DefaultLayout />}>
        <Route index element={<Feed />} />
        <Route path={ROUTES.post} element={<PostPage />} />
        {/* Routes only for assigned users */}
        <Route element={<AssignedUserRoute />}>
          <Route path={ROUTES.profile} element={<Profile />} />
          <Route path={ROUTES.settings.main} element={<Settings />} />
          <Route path={ROUTES.settings.profile} element={<SettingsProfile />} />
          <Route path={ROUTES.settings.privacy} element={<SettingsPrivacy />} />
          <Route path={ROUTES.settings.premium} element={<SettingsPremium />} />
          <Route path={ROUTES.settings.site} element={<SettingsSite />} />
        </Route>
        <Route path=":slag" element={<OtherProfile />} />
      </Route>

      <Route path="*" element={<Navigate to={ROUTES.home} />} />
      <Route path={ROUTES.error} element={<ErrorPage />} />
    </Route>,
  ),
);

export default router;
