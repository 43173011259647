import React from "react";
import { Navigate, useParams } from "react-router-dom";

import CreateAccount from "../../components/auth/create-account";
import { signUpTypeLinks } from "../../components/auth/links/sign-up-type-links";
import LoginComponent from "../../components/auth/login";
import RemindPassword from "../../components/auth/remind";
import ROUTES from "../../routing/routes";
import { getStartPageTitle } from "../../utils/helpers";
import { hasJWT } from "../../utils/helpers/utils";
import useLogin from "./hooks/use-login";
import useSignUp from "./hooks/use-sign-up";
import styles from "./start-page.module.scss";

const StartPage = () => {
  const { type } = useParams();
  const { handleLogin, loginError, isLoginLoading } = useLogin();
  const { handleSignUp, isSignUpLoading, signUpError } = useSignUp();

  const getComponentByParams = (type) => {
    switch (type) {
      case "sign-up":
        return (
          <form className={styles.formWrap}>
            <div className={styles.title}>{getStartPageTitle(type)}</div>

            <CreateAccount
              onSubmit={handleSignUp}
              error={signUpError}
              isLoading={isSignUpLoading}
            />

            {signUpTypeLinks[typeForActions]}
          </form>
        );
      case "remind":
        return (
          <form className={styles.formWrap}>
            <div className={styles.title}>{getStartPageTitle(type)}</div>
            <RemindPassword />
            {signUpTypeLinks[typeForActions]}
          </form>
        );
      case "login":
      default:
        return (
          <form className={styles.formWrap}>
            <div className={styles.title}>{getStartPageTitle(type)}</div>
            <LoginComponent
              onSubmit={handleLogin}
              error={loginError}
              isLoading={isLoginLoading}
            />
            {signUpTypeLinks[typeForActions]}
          </form>
        );
    }
  };

  if (hasJWT()) {
    return <Navigate to={ROUTES.home} replace />;
  }

  const typeForActions = type || "login";
  return (
    <>
      <div className={styles.wrapper}>{getComponentByParams(type)}</div>
    </>
  );
};

export { StartPage };
