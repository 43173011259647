import { MessageText1 } from "iconsax-react";

export const POST_ACTION_TYPES = {
  UP: "up",
  DOWN: "down",
  COMMENT: "comment",
  REPOST: "repost",
  REMOVE_REQUEST: "remove_request",
  REMOVE: "remove",
  EDIT: "edit",
  REPORT: "report",
};

export const PostToolbarActions = [
  {
    type: POST_ACTION_TYPES.COMMENT,
    icon: <MessageText1 size="18" />,
    source: "comments_count",
  },
];
