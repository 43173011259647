import * as $vars from "./variables";

export const lightTheme = {
  token: {
    colorPrimary: "#7E7AFF",
    colorLink: "#7E7AFF",
    fontFamily:
      "'Road UI', 'Segoe UI', 'Droid Sans', 'Helvetica Neue', sans-serif",
    lineHeight: "1.43",
    colorLinkHover: "#4744ce",
  },
  components: {
    Avatar: {
      lineWidth: "0",
    },
    Button: {
      defaultBg: "#F4F4F4",
      defaultBorderColor: "#F4F4F4",
      defaultColor: $vars.colors.dark,
      borderRadiusLG: 8,
      transition: "none",
      fontWeight: "700",
      paddingBlockLG: "8px",
      paddingInlineLG: "16px",
    },
    Drawer: {
      padding: 16,
      paddingLG: 16,
      paddingXS: 16,
      colorSplit: "transparent",
    },
    Input: {
      paddingBlockLG: 12,
      paddingInlineLG: 14,
      lineHeightLg: "19px !important",
      borderRadiusLG: 8,
      colorErrorText: $vars.colors.red,
    },
    Divider: {
      colorText: $vars.colors.grey,
    },
    Modal: {
      titleFontSize: "18px",
      titleLineHeight: "20px",
      fontSize: "16px",
      fontWeightStrong: "700",
      contentBg: "#F8F8F8",
      headerBg: "transparent",
    },
    Upload: {
      borderRadiusLG: 6,
      lineWidth: 2,
      colorBorder: $vars.colors.lightPurple,
      colorFillAlter: "transparent",
    },
  },
};

export const darkTheme = {
  token: {},
};
