import ROUTES from "../../routing/routes";
import { load } from "./local-storage";

export const getScrollKey = (location) => {
  const paths = [ROUTES.home, ROUTES.profile];
  return paths.includes(location.pathname) ? location.pathname : location.key;
};

export function hasJWT() {
  return load("accessToken");
}
