import "./App.scss";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import { RouterProvider } from "react-router-dom";
import router from "./routing";
import { lightTheme } from "./utils/constants/theme";
import CableProvider from "./utils/providers/CableProvider";
import PostsUpdateProvider from "./utils/providers/PostsUpdateProvider";

const App = () => {
  return (
    <ConfigProvider theme={lightTheme}>
      <CableProvider>
        <PostsUpdateProvider>
          <RouterProvider router={router} />
        </PostsUpdateProvider>
      </CableProvider>
    </ConfigProvider>
  );
};

export default App;
