import { Button, Dropdown } from "antd";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

import styles from "./profile-header-actions.module.scss";

const ProfileHeaderActions = ({ items, onActionCLick }) => {
  return (
    <Dropdown
      menu={{
        items: items,
        onClick: onActionCLick,
      }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <Button type="text" size="large" className={styles.profileMore}>
        <BsThreeDotsVertical size={20} />
      </Button>
    </Dropdown>
  );
};

export { ProfileHeaderActions };
