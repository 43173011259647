import { Flex } from "antd";
import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { PostList } from "../../components/post/posts-list";
import { FullscreenAvatars } from "../../components/profile/fullscreen-avatars";
import { OtherProfileActionsButton } from "../../components/profile/other-profile-actions-button";
import { ProfileBio } from "../../components/profile/profile-bio";
import ProfileHeader from "../../components/profile/profile-header";
import { ProfileNoPosts } from "../../components/profile/profile-no-posts";
import { BlockedContainer } from "../../components/ui/blocked-container";
import { Spinner } from "../../components/ui/spinner";
import {
  useGetOtherProfileQuery,
  useGetSelfProfileQuery,
} from "../../features/api/user-api";
import useOtherProfilePosts from "../../hooks/use-other-profile-posts";
import ROUTES from "../../routing/routes";
import styles from "./other-profile.module.scss";

const OtherProfile = () => {
  const { slag } = useParams();
  const [fullScreenOpenedImage, setFullScreenOpenedImage] = useState(null);

  const { data: selfProfileData } = useGetSelfProfileQuery();
  const { data: profileData, isFetching: isProfileLoading } =
    useGetOtherProfileQuery(slag);

  if (selfProfileData?.username === slag) {
    return <Navigate to={ROUTES.profile} replace />;
  }

  const {
    posts,
    error,
    isLoading,
    onPostAction,
    getPosts,
    hasMorePosts,
    isFetchingNextPage,
  } = useOtherProfilePosts({
    skip:
      !profileData?.id ||
      !selfProfileData?.username ||
      selfProfileData?.username !== slag,
    profile_id: profileData?.id,
  });

  const onAvatarClick = async () => {
    if (profileData?.profile_image?.large?.url) {
      setFullScreenOpenedImage({
        index: 0,
        image: profileData?.profile_image?.large?.url,
      });
    }
  };

  const isBlocked = profileData?.relations?.block;
  const hasPosts = posts?.length > 0;

  return (
    <div className={styles.twoColumns}>
      {fullScreenOpenedImage && (
        <FullscreenAvatars
          onAttachmentEvent={setFullScreenOpenedImage}
          startIndex={fullScreenOpenedImage.index}
          profileId={profileData?.id}
        />
      )}
      <Flex vertical className={styles.column}>
        <ProfileHeader
          onAvatarClick={onAvatarClick}
          profile={profileData}
          isLoading={isProfileLoading}
          bioComponent={
            <ProfileBio isEditing={false} value={profileData?.bio} />
          }
          topRightComponent={
            <>
              <OtherProfileActionsButton
                username={profileData?.username}
                relation={profileData?.relations}
              />
            </>
          }
        />
        <div>
          {isBlocked && <BlockedContainer />}
          {isBlocked === false && (
            <>
              <PostList
                posts={posts}
                onAction={onPostAction}
                getPosts={getPosts}
                hasMorePosts={hasMorePosts}
              />
              {!hasPosts && !isLoading && <ProfileNoPosts />}
              {isFetchingNextPage && <Spinner />}
              {error?.length && <div>{error}</div>}
            </>
          )}
        </div>
      </Flex>
    </div>
  );
};

export { OtherProfile };
