import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./back-button.module.scss";

const BackButton = ({ children }) => {
  const location = useLocation();
  const [to, setTo] = useState("/");

  useEffect(() => {
    if (window.history.length > 2) {
      setTo(-1);
    } else {
      setTo("/");
    }
  }, [location]);
  return (
    <Link to={to} preventScrollReset={true} className={styles.link}>
      {children}
    </Link>
  );
};

export { BackButton };
