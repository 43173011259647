import { Modal } from "antd";
import React from "react";

const RemoveModal = ({ title, text, onSubmit, onCancel }) => {
  return (
    <Modal
      centered
      width={500}
      title={title}
      open={true}
      okText={"Видалити"}
      cancelText={"Скасувати"}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      {text}
    </Modal>
  );
};

export { RemoveModal };
