import {useState} from "react";
import {Checkbox, Divider, Flex, Input, Modal, Radio, Space} from "antd";

import styles from "./report-modal.module.scss";
import {Link} from "react-router-dom";

const ReportModal = ({ title, onSubmit, onCancel }) => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <Modal
      centered
      width={700}
      title={title}
      open={true}
      okText={"Поскаржитись"}
      cancelText={"Скасувати"}
      onOk={onSubmit}
      onCancel={onCancel}
      className={styles.reportModalWrap}
    >
      <div className={styles.reportModalTitle}>Будь ласка, виберіть причину, чому ви хочете поскаржитися на цей пост:</div>
      <Flex vertical gap={8} className={styles.reportModal}>
        <Radio.Group
          name="post-report"
          direction="vertical"
          defaultValue={"1"}
          onChange={onChange}
          value={value}
        >
          <Flex vertical gap={16} style={{width:'100%'}}>
            <Radio value={1} rootClassName={styles.reportModalItem}>
              <Flex vertical gap={2} className={styles.reportModalItemLabel}>
                <div className={styles.reportModalItemTitle}>Порушує правила соцмережі</div>
                <div className={styles.reportModalItemText}>Публікація містить спам або рекламні матеріали.</div>
              </Flex>
            </Radio>
            <Radio value={2} rootClassName={styles.reportModalItem}>
              <Flex vertical gap={2} className={styles.reportModalItemLabel}>
                <div className={styles.reportModalItemTitle}>Порушення авторських прав</div>
                <div className={styles.reportModalItemText}>Публікація містить матеріали, що порушують авторські права.</div>
              </Flex>
            </Radio>
            <Radio value={3} rootClassName={styles.reportModalItem}>
              <Flex vertical gap={2}  className={styles.reportModalItemLabel}>
                <div className={styles.reportModalItemTitle}>Інше</div>
                {value == 3 ? (
                  <div>
                    <Input.TextArea
                      autoFocus={true}
                      autoSize={{
                        minRows: 1,
                        maxRows: 8,
                      }}
                      placeholder="Будь ласка, вкажіть причину скарги"
                      className={styles.reportModalItemTextarea}
                    />
                  </div>
                ) : null}
              </Flex>
            </Radio>
          </Flex>
        </Radio.Group>
        <Divider className={styles.reportModalDivider}/>
        <Checkbox className={styles.reportModalItemTitle}>Також заблокувати користувача <Link to={"#"} className={styles.reportModalCheckboxName}>Donald Trump</Link></Checkbox>
      </Flex>
    </Modal>
  );
};

export { ReportModal };