import cn from "@/utils/helpers/className";
import { Flex } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import {
  GALLERY_ANIMATION_SPEED,
  GALLERY_SLIDES_TO_SCROLL,
  GALLERY_SLIDES_TO_SHOW,
} from "../../../../utils/constants/integers";
import styles from "./image-modal.module.scss";

const ImageModal = ({ images, onNavigation, startFromIndex, infoSection }) => {
  const [currentImage, setCurrentImage] = useState(startFromIndex);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onNavigation(null);
      } else if (event.key === "ArrowLeft") {
        sliderRef1.current?.slickPrev();
        sliderRef2.current?.slickPrev();
      } else if (event.key === "ArrowRight") {
        sliderRef1.current?.slickNext();
        sliderRef2.current?.slickNext();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onNavigation]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const isMoreThanOneImage = images && images.length > 1;

  const PrevArrow = ({ onClick }) => (
    <div className={styles.modalThumbsPrev} onClick={onClick}>
      <FaAngleLeft size="24" color={"white"} />
    </div>
  );

  const NextArrow = ({ onClick }) => (
    <div className={styles.modalThumbsNext} onClick={onClick}>
      <FaAngleRight size="24" color={"white"} />
    </div>
  );

  return (
    <div
      id="modal"
      className={styles.modal}
      onClick={(e) => e.target.id === "modal" && onNavigation(null)}
    >
      <div className={styles.modalHeader}>
        {infoSection}
        <div className={styles.modalClose} onClick={() => onNavigation(null)}>
          <MdClose size="32" color={"white"} />
        </div>
      </div>
      <div className={styles.modalBody}>
        {isMoreThanOneImage && (
          <div
            className={styles.navigationButton}
            style={{ left: "0" }}
            onClick={() => sliderRef1.current.slickPrev()}
          >
            <FaAngleLeft size="32" color={"white"} />
          </div>
        )}
        <div className={styles.sliderWrapper}>
          <Slider
            initialSlide={startFromIndex}
            beforeChange={(_, next) => setCurrentImage(next)}
            asNavFor={sliderRef2.current}
            arrows={false}
            ref={sliderRef1}
            fade={true}
            speed={GALLERY_ANIMATION_SPEED}
          >
            {images &&
              images.length > 0 &&
              images.map((image) => (
                <div key={image?.large?.url}>
                  <div
                    className={styles.modalBlur}
                    style={{
                      backgroundImage: "url(" + image?.large?.url + ")",
                    }}
                  />
                  <Flex
                    className={styles.modalImageWrap}
                    key={image?.large?.url}
                  >
                    <img src={image?.large?.url} alt="" />
                  </Flex>
                </div>
              ))}
          </Slider>
        </div>
        {isMoreThanOneImage && (
          <div
            className={styles.navigationButton}
            style={{ right: "0" }}
            onClick={() => sliderRef1.current.slickNext()}
          >
            <FaAngleRight size="32" color={"white"} />
          </div>
        )}
      </div>
      <div className={styles.modalFooter}>
        {isMoreThanOneImage && (
          <div className={styles.modalControls}>
            <div className={styles.modalCounter}>
              {currentImage + 1 + " з " + images.length}
            </div>
            <div className={styles.modalThumbs}>
              <PrevArrow onClick={() => sliderRef2.current.slickPrev()} />
              <div className={styles.modalThumbsList}>
                <div className={cn(styles.previewWrapper, "slider-container")}>
                  <Slider
                    asNavFor={sliderRef1.current}
                    ref={sliderRef2}
                    slidesToShow={Math.min(
                      images.length,
                      GALLERY_SLIDES_TO_SHOW,
                    )}
                    slidesToScroll={GALLERY_SLIDES_TO_SCROLL}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    centerMode={true}
                    centerPadding={0}
                    arrows={true}
                    prevArrow={<PrevArrow />}
                    nextArrow={<NextArrow />}
                    className={"thumbs-slider"}
                    speed={0}
                  >
                    {images.map((image) => (
                      <div
                        className={styles.previewImageWrapper}
                        key={image?.large?.url}
                      >
                        <img src={image?.large?.url} alt="" />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              <NextArrow onClick={() => sliderRef1.current.slickNext()} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { ImageModal };
