import { Flex, List, Typography } from "antd";
import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

import ROUTES from "../../routing/routes";
import styles from "./settings.module.scss";

const data = [
  {
    title: "Основні налаштування",
    description:
      "Кольорова схема, відображення елементів за замовчуванням, сортування дописів та інше",
    link: ROUTES.settings.main,
  },
  {
    title: "Змінити профіль",
    description: "Фото профіля, Ім`я, опис та посилання",
    link: ROUTES.settings.profile,
  },
  {
    title: "Конфіденційність та безпека",
    description: "Видимість профіля, налаштування приватності",
    link: ROUTES.settings.privacy,
  },
  {
    title: "Преміум акаунт",
    description: "Супер фічі, доступні за підпискою. Блекджек та шльондри",
    link: ROUTES.settings.premium,
  },
];

const Settings = () => {
  const navigate = useNavigate();
  return (
    <List className={styles.wrapSettings}>
      <Flex>
        <div className={styles.backButtonWrap}>
          <div className={styles.backButton} onClick={() => navigate("/")}>
            <div>
              <BiArrowBack />
            </div>
          </div>
        </div>
        <Typography.Title className={styles.settingsH1} level={3}>
          Налаштування
        </Typography.Title>
      </Flex>
      <List
        className={styles.wrapList}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item className={styles.wrapListItem}>
            <Link
              className={styles.wrapListItemLink}
              to={item.link}
              style={{ padding: "12px 20px" }}
            >
              <Typography.Title
                className={styles.settingsTitle}
                level={5}
                style={{ margin: "0 !important" }}
              >
                {item.title}
              </Typography.Title>
              <Typography.Text
                type="secondary"
                style={{ margin: "0 !important" }}
              >
                {item.description}
              </Typography.Text>
            </Link>
          </List.Item>
        )}
      />
    </List>
  );
};

export { Settings };
