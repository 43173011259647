import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "react-image-crop/dist/ReactCrop.css";
import { Provider } from "react-redux";

import App from "./App";
import { store } from "./features/store";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense fallback="Loading...">
      <App />
    </Suspense>
  </Provider>,
);
