import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "ua",
  theme: "light",
};

export const userSettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeTheme: (state) => {
      if (state.theme === "dark") {
        state.theme = "light";
      } else {
        state.theme = "dark";
      }
    },
    changeLocale: (state) => {
      if (state.language === "ua") {
        state.language = "en";
      } else {
        state.language = "ua";
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTheme, changeLocale } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
