import ROUTES from "@/routing/routes";
import { formatDateTemplate } from "@/utils/helpers/date";
import { Avatar, Flex, Popover, Tooltip, Typography } from "antd";
import { format } from "date-fns";
import { ArrowRight } from "iconsax-react";
import React, { memo, useState } from "react";
import { LuUser2 } from "react-icons/lu";
import { Link, generatePath } from "react-router-dom";

import AddCommentForm from "../../forms/add-comment-form/add-comment-form";
import PopoverUser from "../../ui/popovers/popover-user";
import { Spinner } from "../../ui/spinner";
import CommentsActions from "../comments-actions";
import styles from "./thread-comment.module.scss";

const ThreadComment = ({
  comment: {
    id,
    body,
    votes_up,
    votes_down,
    reposts_count,
    comments_count,
    profile: { display_name, username, profile_image },
    published_at_str,
    isLoading,
    comment_last_id,
    comments_left,
    published_at,
    error,
    vote,
    votes_sum,
    reply_to,
    permissions,
  },
  onAction,
  parentId,
  isLast,
}) => {
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const handleAction = (type, metadata) => {
    if (type === "comment") {
      setIsReplyOpen(!isReplyOpen);
    }
    return onAction({
      type,
      id,
      parentId,
      metadata,
    })?.then((res) => {
      if (type === "reply" && res?.payload?.id) {
        setIsReplyOpen(false);
      }
    });
  };
  return (
    <div className={styles.threadCommentWrap}>
      {!isLast && <div className={styles.secondLevelLine} />}
      <Flex className={styles.threadCommentBox} gap={8}>
        <Link
          to={generatePath(ROUTES.otherProfile, { slag: username })}
          style={{ height: "40px", border: "1px solid red" }}
        >
          <Avatar
            style={{ outline: "4px solid white", border: "none" }}
            shape="square"
            size="large"
            icon={
              profile_image?.small?.url ? (
                <img src={profile_image?.small?.url} alt={"avatar"} />
              ) : (
                <LuUser2 />
              )
            }
          />
        </Link>
        <Flex vertical gap={4} flex={1}>
          <Flex className={styles.commentHeader} align={"center"}>
            <Link
              className={styles.commentAuthor}
              to={generatePath(ROUTES.otherProfile, { slag: username })}
            >
              <Popover
                placement="bottomLeft"
                content={<PopoverUser slug={username} />}
                title={null}
                className={"user-popover"}
                style={{ width: "350px" }}
              >
                {display_name}
              </Popover>
            </Link>
            <Tooltip
              title={
                published_at?.length && format(published_at, formatDateTemplate)
              }
            >
              <Link
                className={styles.commentDateTime}
                to={generatePath(ROUTES.otherProfile, { slag: username })}
              >
                {published_at_str}
              </Link>
            </Tooltip>
            {reply_to?.profile?.display_name && (
              <>
                <ArrowRight
                  size="13"
                  color="#ccc"
                  style={{ margin: "0 8px" }}
                />
                <Link className={styles.commentReplyTo} to={"#"}>
                  <Popover
                    placement="bottomLeft"
                    content={<PopoverUser slug={reply_to?.profile?.username} />}
                    title={null}
                    className={"user-popover"}
                    style={{ width: "350px" }}
                  >
                    {reply_to?.profile?.display_name}
                  </Popover>
                </Link>
              </>
            )}
          </Flex>
          <div className={styles.commentBody}>{body}</div>
          <div style={{ marginLeft: "-4px" }}>
            <CommentsActions
              permissions={permissions}
              votesCount={votes_sum}
              activeVote={vote?.vote_type}
              onAction={handleAction}
              counters={{
                id,
                votes_up,
                votes_down,
                reposts_count,
                comments_count,
              }}
            />
          </div>
          {isReplyOpen && (
            <div style={{ marginTop: "8px" }}>
              <AddCommentForm
                onPostComment={(commentData) =>
                  handleAction("reply", commentData)
                }
              />
            </div>
          )}
        </Flex>
      </Flex>
      {isLoading && <Spinner />}
      {error && <Typography.Text>{error}</Typography.Text>}
    </div>
  );
};

const memoizedThread = memo(ThreadComment);

export { memoizedThread as ThreadComment };
