import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createPost,
  getPostById,
  getPosts,
  removePost,
  updatePost,
  updateVote,
} from "../../services/posts-service";

export const fetchPosts = createAsyncThunk(
  "posts/fetchPosts",
  async ({ from_id, profile_id, source }) => {
    const response = await getPosts({ from_id, profile_id });
    return response.data;
  },
);

export const fetchNextPosts = createAsyncThunk(
  "posts/fetchNextPosts",
  async ({ from_id, profile_id, source }, thunkAPI) => {
    const response = await getPosts({ from_id, profile_id });
    return response.data;
  },
);

export const fetchUpdateVote = createAsyncThunk(
  "posts/fetchUpdateVote",
  async ({ postId, voteType }, thunkAPI) => {
    const response = await updateVote({ postId, voteType });
    return response.data;
  },
);

export const fetchRemovePost = createAsyncThunk(
  "posts/fetchRemovePost",
  async ({ postId, source }, thunkAPI) => {
    const response = removePost(postId);
    return response.data;
  },
);

export const fetchCreatePost = createAsyncThunk(
  "posts/fetchCreatePost",
  async ({ body }, thunkAPI) => {
    try {
      const response = await createPost(body);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const fetchUpdatePost = createAsyncThunk(
  "posts/fetchUpdatePost",
  async ({ id, body }, thunkAPI) => {
    try {
      const response = await updatePost(id, body);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const fetchPostById = createAsyncThunk(
  "posts/fetchPostById",
  async ({ id }, thunkAPI) => {
    try {
      const response = await getPostById(id);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
