import { Flex, Tag } from "antd";
import React, { useCallback } from "react";
import { BiArrowBack } from "react-icons/bi";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { BackButton } from "../../components/back-button";
import { Comments } from "../../components/comments/comments";
import { PostCard } from "../../components/post/post-card";
import { Spinner } from "../../components/ui/spinner";
import {
  useGetPostByIdQuery,
  useRemovePostMutation,
  useUpdateVoteOnSinglePostMutation,
} from "../../features/api/posts-api";
import { removePostFromState } from "../../features/posts/posts-slice";
import { usePostComments } from "./hooks/usePostComments";
import styles from "./post-page.module.scss";

const PostPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: post, error: postError, isLoading } = useGetPostByIdQuery(id);
  const [removePost] = useRemovePostMutation();
  const [updateVote] = useUpdateVoteOnSinglePostMutation();

  const {
    fetchCommentVote,
    getComments,
    onAddNewThread,
    onAddCommentToThread,
    handleLoadNextThreadComments,
    isLoading: isLoadingThreads,
    error: threadsError,
    isLoadingNext: isLoadingNextThreads,
    data: threads,
    commentsLeft: numberOfNotLoadedThreads,
    handleCommentRemove,
  } = usePostComments({ id });

  const onCommentAction = useCallback(
    ({ type, id: commentId, parentId, metadata }) => {
      switch (type) {
        case "vote": {
          const { type } = metadata;
          fetchCommentVote({
            comment_id: commentId,
            vote_type: type,
            post_id: id,
            parentId,
          });
          break;
        }
        case "comment": {
          if (!parentId) {
            console.log("comment");
          }
          break;
        }
        case "reply": {
          if (metadata) {
            return onAddCommentToThread({
              postId: id,
              body: metadata,
              commentId: commentId || null,
            });
          }
          break;
        }
        case "preload_comments": {
          handleLoadNextThreadComments({
            fromId: metadata.comment_last_id,
            postId: id,
            parentId: commentId,
          });
          break;
        }
        case "remove": {
          handleCommentRemove({
            comment_id: commentId,
            thread_id: parentId,
            post_id: id,
          });
          break;
        }
      }
    },
    [id],
  );

  const onPostRemove = async () => {
    try {
      await removePost(id);
      dispatch(removePostFromState(id));
      navigate("/", {
        state: { scrollPos: location?.state?.scrollPos ?? undefined },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onPostAction = ({ type, id: commentId, metadata }) => {
    switch (type) {
      case "comment": {
        break;
      }
      case "reply": {
        if (metadata) {
          return onAddNewThread({ body: metadata, postId: id });
        }
        break;
      }
      case "vote": {
        const { type } = metadata;
        updateVote({ postId: id, voteType: type });
        break;
      }
      case "remove": {
        onPostRemove();
      }
    }
  };

  const canViewPost = !isLoading && !postError;
  const commentsLoadingErrorMessageVisible =
    threadsError && !isLoadingThreads && !isLoadingNextThreads;
  const updateCommentsButtonVisible =
    !isLoadingNextThreads && !isLoading && !isLoadingThreads && !threadsError;

  return (
    <div className={styles.pageWrap}>
      <div className={styles.backButtonWrap}>
        <BackButton>
          <div>
            <BiArrowBack />
          </div>
        </BackButton>
      </div>
      <Flex className={styles.feedWrap} vertical gap={8}>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Spinner />
          </div>
        )}
        {canViewPost && (
          <PostCard post={post} onAction={onPostAction} hasCommentForm={true} />
        )}
        {isLoadingThreads && (
          <div className={styles.infinitiLoaderWrapper}>
            <Spinner />
          </div>
        )}
        <Flex vertical gap={12}>
          <InfiniteScroll
            initialLoad={false}
            loadMore={getComments}
            hasMore={Boolean(
              numberOfNotLoadedThreads > 0 && !isLoadingNextThreads,
            )}
            // useWindow={true}
            threshold={500}
          >
            <Comments comments={threads} onAction={onCommentAction} />
          </InfiniteScroll>
        </Flex>
        {commentsLoadingErrorMessageVisible && (
          <div className={styles.wrapperCentered}>{threadsError}</div>
        )}
        {isLoadingNextThreads && (
          <div className={styles.infinitiLoaderWrapper}>
            <Spinner />
          </div>
        )}
        {updateCommentsButtonVisible && (
          <div className={styles.wrapperCentered}>
            <Tag
              color="default"
              onClick={getComments}
              style={{ cursor: "pointer" }}
            >
              {!threads?.length
                ? "Оновити коментарі"
                : "Завантажити ще коментарі..."}
            </Tag>
          </div>
        )}
      </Flex>
    </div>
  );
};

export { PostPage };
