import { FULL_UPLOADED_IMAGE_PERCENT } from "@/utils/constants/integers";
import * as vars from "@/utils/constants/variables";
import { Progress, Skeleton } from "antd";
import React from "react";
import { GrClose } from "react-icons/gr";

import styles from "./uploaded-image.module.scss";

const UploadedImage = ({ image, onRemove }) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const isEarlyUploaded = Boolean(image.medium || image.large);
  const imageUrl = isEarlyUploaded
    ? image?.medium?.url || image?.large?.url
    : URL.createObjectURL(image.file);

  return (
    <div className={styles.attachmentItem} key={image.id}>
      <div className={styles.attachmentItemImg}>
        {!isLoaded && isEarlyUploaded && (
          <Skeleton.Image
            style={{
              width: "100%",
              height: "160px",
              borderRadius: "4px",
            }}
            active
          />
        )}
        <img
          onLoad={() => setIsLoaded(true)}
          src={imageUrl}
          style={{
            pointerEvents: "none",
            userSelect: "none",
            display: isLoaded ? "block" : "none",
            width: "100%",
            height: "160px",
            objectFit: "cover",
            filter:
              image.process < FULL_UPLOADED_IMAGE_PERCENT
                ? "blur(2px)"
                : "none",
          }}
          alt="attachment"
        />
        <div
          className={styles.attachmentRemove}
          onClick={() => onRemove(image)}
        >
          <GrClose />
        </div>
      </div>
      {!isEarlyUploaded && image.process < FULL_UPLOADED_IMAGE_PERCENT && (
        <Progress
          type="circle"
          size={30}
          strokeColor={vars.colors.primaryColor}
          style={{
            position: "absolute",
            top: "calc(50% - 15px)",
            left: "calc(50% - 15px)",
            zIndex: 1,
          }}
          percent={image.process}
          showInfo={false}
        />
      )}
    </div>
  );
};

export default UploadedImage;
