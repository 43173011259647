import { Divider } from "antd";
import React from "react";

import RemindForm from "../../forms/remind-password";

const RemindPassword = ({ error, onSubmit }) => {
  return (
    <>
      <RemindForm onSubmit={onSubmit} error={error} />
      <Divider />
    </>
  );
};

export default RemindPassword;
