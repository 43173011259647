import { createSlice } from "@reduxjs/toolkit";

import {
  fetchCommentVote,
  fetchComments,
  fetchNextThreads,
  fetchPostComment,
  fetchRemoveComment,
  fetchThreadsForPost,
} from "./post-actions";

const initialState = {
  comments: {
    isLoading: true,
    data: [],
    error: null,
    comment_first_id: null,
    comment_last_id: null,
    isLoadingNext: false,
    hasNewComments: true,
  },
  isLoading: true,
  error: null,
};

export const postSlice = createSlice({
  name: "post",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchThreadsForPost.fulfilled, (state, action) => {
      state.comments.isLoading = false;
      state.comments.error = null;
      state.comments.data = action.payload.comments;
      state.comments.comment_first_id = action.payload.comment_first_id;
      state.comments.comment_last_id = action.payload.comment_last_id;
      state.comments.comments_left = action.payload.comments_left;
    });
    builder.addCase(fetchThreadsForPost.pending, (state) => {
      state.isLoading = true;
      state.comments.error = null;
      state.comments.data = [];
      state.comments.comment_first_id = null;
      state.comments.comment_last_id = null;
      state.comments.comments_left = null;
    });
    builder.addCase(fetchThreadsForPost.rejected, (state, action) => {
      state.comments.isLoading = false;
      state.comments.error = action.payload;
      state.comments.data = [];
      state.comments.comment_first_id = null;
      state.comments.comment_last_id = null;
      state.comments.comments_left = null;
    });
    builder.addCase(fetchNextThreads.fulfilled, (state, action) => {
      state.comments.isLoadingNext = false;
      state.error = null;
      state.comments.data = [
        ...state.comments.data,
        ...action.payload.comments,
      ];
      if (action.payload.comment_last_id) {
        state.comments.comment_last_id = action.payload.comment_last_id;
      }
      state.comments.comments_left = action.payload.comments_left;
    });
    builder.addCase(fetchNextThreads.pending, (state) => {
      state.comments.isLoadingNext = true;
      state.error = null;
    });
    builder.addCase(fetchNextThreads.rejected, (state) => {
      state.comments.isLoadingNext = false;
      state.comments.error = "Something went wrong";
    });
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      const threadId = action.meta.arg.parent_id;
      const currentTread = state.comments.data.find(
        (item) => item.id === Number(threadId),
      );
      currentTread.comments.push(...action.payload.comments);
      currentTread.comments_left = action.payload.comments_left;
      currentTread.comment_first_id = action.payload.comment_first_id;
      currentTread.comment_last_id = action.payload.comment_last_id;
      currentTread.isLoading = false;
      const newComments = currentTread?.comments.filter(
        (a) => !action.payload?.comments?.some((b) => a.id === b.id),
      );
      currentTread.comments = [...newComments, ...action.payload.comments];
    });
    builder.addCase(fetchComments.pending, (state, action) => {
      const threadId = action.meta.arg.parent_id;
      const currentTread = state.comments.data.find(
        (item) => item.id === Number(threadId),
      );
      currentTread.isLoading = true;
    });
    builder.addCase(fetchComments.rejected, (state, action) => {
      const threadId = action.meta.arg.parent_id;
      const currentTread = state.comments.data.find(
        (item) => item.id === Number(threadId),
      );
      currentTread.isLoading = false;
      currentTread.error = "Something went wrong";
    });
    builder.addCase(fetchPostComment.fulfilled, (state, action) => {
      const isThread = action.payload.thread_id === null;
      if (isThread) {
        state.comments.data.unshift(action.payload);
        state.comments.comment_last_id = action.payload.id;
      } else {
        const threadId = action.payload.thread_id;
        const currentTread = state.comments.data.find(
          (item) => item.id === Number(threadId),
        );
        currentTread.comments =
          currentTread?.comments?.length > 0
            ? [...currentTread.comments, action.payload]
            : [action.payload];
      }
    });
    builder.addCase(fetchPostComment.pending, () => {});
    builder.addCase(fetchPostComment.rejected, () => {});
    builder.addCase(fetchCommentVote.fulfilled, (state, action) => {
      const commentId = action.meta.arg.comment_id;
      const parentId = action.meta.arg.parentId;

      if (parentId === undefined) {
        const currentTread = state.comments.data.find(
          (item) => item.id === Number(commentId),
        );
        currentTread.votes_sum =
          currentTread.votes_sum + Number(action.payload.vote_delta);
        currentTread.vote = action.payload;
      } else {
        const currentTread = state.comments.data.find(
          (item) => item.id === Number(parentId),
        );
        const currentComment = currentTread.comments.find(
          (item) => item.id === Number(commentId),
        );
        currentComment.votes_sum =
          currentComment.votes_sum + Number(action.payload.vote_delta);
        currentComment.vote = action.payload;
      }
    });
    builder.addCase(fetchCommentVote.pending, () => {});
    builder.addCase(fetchCommentVote.rejected, () => {});
    builder.addCase(fetchRemoveComment.fulfilled, (state, action) => {
      const commentId = action.meta.arg.comment_id;
      const threadId = action.meta.arg.thread_id;

      if (threadId === undefined) {
        state.comments.data = state.comments.data.filter(
          (item) => item.id !== Number(commentId),
        );
      } else {
        const currentTread = state.comments.data.find(
          (item) => item.id === Number(threadId),
        );
        currentTread.comments = currentTread.comments.filter(
          (item) => item.id !== Number(commentId),
        );
      }
    });
    builder.addCase(fetchRemoveComment.pending, () => {});
    builder.addCase(fetchRemoveComment.rejected, () => {});
  },
});

export default postSlice.reducer;
