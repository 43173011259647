import { Button, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";

import styles from "./profile-bio.module.scss";

const ProfileBio = ({ isEditing, value, onCancel, onSave }) => {
  const [expanded, setExpanded] = useState(false);
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  if (isEditing) {
    return (
      <>
        <div className={styles.formPostWrap}>
          <Input.TextArea
            className={styles.formTextarea}
            autoFocus
            autoSize
            value={innerValue}
            onChange={(e) => setInnerValue(e.target.value)}
          >
            test
          </Input.TextArea>
        </div>
        <div className={styles.bioSaveButtons}>
          <Button type="default" size="large" onClick={onCancel}>
            Скасувати
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => onSave(innerValue)}
          >
            Зберегти
          </Button>
        </div>
      </>
    );
  }
  return (
    <Typography.Paragraph
      className={styles.profileDesc}
      ellipsis={{
        rows: 3,
        expandable: "collapsible",
        expanded,
        onExpand: () => setExpanded(true),
        symbol: "Читати далі",
      }}
    >
      {value || "Немає опису"}
    </Typography.Paragraph>
  );
};

export { ProfileBio };
