import * as $vars from "@/utils/constants/variables";
import { Badge, Drawer } from "antd";
import { Notification } from "iconsax-react";
import { useState } from "react";

import styles from "../ui/header/header.module.scss";
import { Message } from "./message";
import { Notice } from "./notice";

const Notifications = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Badge
        status="success"
        dot={true}
        onClick={showDrawer}
        offset={[-16, 14]}
      >
        <div className={styles.notifyButton}>
          <Notification size="24" color={$vars.colors.grey} />
        </div>
      </Badge>
      <Drawer title="Сповіщення" onClose={onClose} open={open} extra={true}>
        <Message />
        <Notice />
        <Notice />
        <Notice />
        <Notice />
      </Drawer>
    </>
  );
};

export { Notifications };
