import { MessageText1 } from "iconsax-react";

export const COMMENTS_ACTION_TYPES = {
  UP: "up",
  DOWN: "down",
  COMMENT: "comment",
  REPOST: "repost",
  REMOVE: "remove",
};

export const toolbarActions = [
  {
    type: COMMENTS_ACTION_TYPES.COMMENT,
    icon: <MessageText1 size="18" style={{ width: "16px", height: "16px" }} />,
    style: { color: "$grey" },
    text: "Відповісти",
  },
];

export function sortArrayWithPostDataToEnd(arr) {
  const sortedArray = arr.slice();

  sortedArray.sort((a, b) => {
    if (a.published_at && b.published_at) {
      return 0;
    } else if (b.published_at) {
      return 1;
    } else if (a.published_at) {
      return -1;
    } else {
      return a.id - b.id;
    }
  });

  return sortedArray;
}
